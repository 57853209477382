<template>
  <div class="lg:text-xl home relative text-base">
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeader />
    <div class="spacer lg:pt-24 pt-8"></div>
    <div class="bg-sub-banner relative">
      <div class="bg-banner lg:block absolute bottom-0 right-0 z-30 hidden w-2/5 h-full bg-no-repeat bg-contain"></div>
      <carousel :per-page="1" :navigate-to="someLocalProperty" :mouse-drag="true" pagination-padding="10"
        :pagination-enabled="false" pagination-active-color="#5a1e78" pagination-color="#e6c8f0" :loop="true"
        :autoplay="true" autoplay-timeout="3000">
        <slide class="lg:pt-0 md:text-left relative pt-12 overflow-hidden text-center text-gray-800">
          <div class="lg:py-24 max-w-screen-xl px-4 pt-16 mx-auto">
            <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
              <div class="lg:w-3/4 w-full px-4 overflow-hidden">
                <div class="lg:py-24">
                  <h3 class="lg:text-7xl text-sitePurple text-4xl font-semibold leading-tight uppercase">Over 35 Years
                  </h3>
                  <h3 class="lg:text-5xl lg:mb-8 text-sitePurple mb-5 text-3xl font-light leading-tight uppercase">Of
                    Experience In Plastic Surgery</h3>
                  <h2 class="lg:font-light lg:mb-3 lg:text-4xl mb-2 text-2xl leading-tight">Dr Marco, Established
                    Surgeon in <br class="lg:block hidden">Singapore, Mount Elizabeth Novena</h2>
                  <p class="lg:text-xl mb-2">Introducer of Endoscopic Plastic Surgery & Robotic Tummy Tuck</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="lg:pt-0 md:text-left relative pt-12 overflow-hidden text-center text-gray-800">
          <div class="lg:py-24 max-w-screen-xl px-4 pt-16 mx-auto">
            <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
              <div class="lg:w-3/4 w-full px-4 overflow-hidden">
                <div class="lg:py-24">
                  <h3 class="lg:text-7xl text-sitePurple lg:mb-8 mb-5 text-4xl font-semibold leading-none uppercase">
                    Patients First, Always</h3>
                  <h2 class="lg:font-light lg:mb-3 lg:text-4xl mb-2 text-2xl leading-tight">Experienced with
                    international patients <br class="lg:block hidden">across the region</h2>
                  <p class="lg:text-xl mb-2">Achieving patient goals with safe techniques</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
      <img class="lg:hidden" src="images/bg-banner.png">
    </div>
    <section class="text-gray-800">
      <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
        <h3 class="md:mb-5 lg:text-4xl lg:mb-12 mb-8 text-3xl font-semibold leading-tight text-center uppercase">About
          Dr Marco</h3>
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <img class="lg:ml-auto mb-4" src="images/image-doctor.jpg">
          </div>
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <div class="lg:pl-8">
              <p class="lg:text-2xl mb-2 text-xl font-bold">Dr Marco Faria Correa</p>
              <p class="mb-8">
                Physician’s Dip. (Rio Grande do Sul), Post Graduate Cert. (Plastic Surgery, Brazil),<br
                  class="lg:block hidden">
                Specialist Cert. (Plastic Surgery, Brazil), TMSBCP (Plastic Surgery, Brazil),<br
                  class="lg:block hidden">
                Accredited Specialist Plastic Surgeon (Ministry of Health, Singapore)
              </p>
              <p class="lg:text-xl text-sitePurple mb-2 text-lg font-bold">Medical Director & Cosmetic Plastic Surgeon
              </p>
              <ul class="pl-5 mb-4 list-disc">
                <li class="mb-1">Plastic Surgeon with Special Interest in Minimally-Invasive Robotic Plastic Surgery
                </li>
                <li class="mb-1">International Experience in Asia, America, Middle East & Latin America</li>
                <li class="mb-1">Specialized in Tummy Tucks, Mummy Makeovers & Men’s Plastic Surgery</li>
                <li class="mb-1">Over 35 Years Of Experience In Plastic Surgery</li>
                <li class="mb-1">Dedicated To Patient Well-Being</li>
                <li class="mb-1">Awarded By 6 Different Societies For Contributions To Endoscopic & Robotic Surgery</li>
                <li class="mb-1">Passionate About Multi-Area Mummy Makeovers</li>
                <li class="mb-1">Actively Performing Research & Publications Through Workshops, Journals & Conferences
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800 bg-gray-200">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 w-full pt-12 pb-8 mx-auto">
        <h3 class="md:mb-5 lg:text-4xl lg:mb-8 mb-4 text-3xl font-semibold leading-tight text-center uppercase">Dr
          Marco’s Specialties</h3>
        <div class="lg:w-3/4 lg:px-24 w-full px-4 mx-auto text-center">
          <p class="lg:mb-16 lg:px-24 mb-12">
            With over 35 years of international experience, Dr Marco prides himself on his surgical skills and can help
            patients of all ethnicities achieve their aesthetic goals.
          </p>
        </div>
        <div class="lg:px-24">
          <div class="lg:-mx-4 lg:px-24 md:mb-12 lg:mb-16 lg:items-center flex flex-wrap mb-8 overflow-hidden">
            <div class="md:w-1/2 lg:order-2 w-full px-4 overflow-hidden">
              <img class="border-sitePPurple lg:ml-auto mb-4 border-b-8" src="images/image-service-01.jpg">
            </div>
            <div class="md:w-1/2 lg:order-1 w-full px-4 overflow-hidden">
              <div class="lg:pr-8">
                <h4 class="lg:mb-6 lg:text-3xl text-sitePurple mb-4 text-xl font-bold uppercase">Face & Body</h4>
                <div class="lg:pl-8 border-sitePurple pl-4 border-l">
                  <p class="mb-4">
                    Dr Marco has dedicated his career to enhancing the self-confidence of patients from all over the
                    world. With a wide range of treatments, Dr Marco has helped countless patients feel better about
                    their faces and bodies. Rest assured that nothing is more important to Dr Marco than your
                    satisfaction.
                  </p>
                  <p class="mb-4">
                    Find out more about the different procedures Dr Marco provides <a href="services"
                      class="text-sitePurple font-bold">here.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:px-24">
          <div class="lg:-mx-4 lg:px-24 md:mb-12 lg:mb-16 lg:items-center flex flex-wrap mb-8 overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="border-sitePPurple lg:mr-auto mb-4 border-b-8" src="images/image-service-02.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:mb-6 lg:text-3xl text-sitePurple mb-4 text-xl font-bold uppercase">For Men</h4>
                <div class="lg:pl-8 border-sitePurple pl-4 border-l">
                  <p class="mb-4">
                    With a greater proportion of body conscious men in modern society, Dr Marco has seen an increased
                    number of men seeking aesthetic enhancement. To provide encompassing care for all patients, Dr Marco
                    has designed treatments that are specifically tailored for men’s needs, allowing men to enjoy
                    increased confidence with maximum discretion.
                  </p>
                  <p class="mb-4">
                    Find out more about the tailored treatments for men <a href="for-men"
                      class="text-sitePurple font-bold">here.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:px-24">
          <div class="lg:-mx-4 lg:items-center lg:px-24 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 lg:order-2 w-full px-4 overflow-hidden">
              <img class="border-sitePPurple lg:ml-auto mb-4 border-b-8" src="images/image-service-03.jpg">
            </div>
            <div class="md:w-1/2 lg:order-1 w-full px-4 overflow-hidden">
              <div class="lg:pr-8">
                <h4 class="lg:mb-6 lg:text-3xl text-sitePurple mb-4 text-xl font-bold uppercase">Minimally Invasive
                  Plastic Surgery</h4>
                <div class="lg:pl-8 border-sitePurple pl-4 border-l">
                  <p class="mb-4">
                    As part of Dr Marco’s dedication to safe, effective treatments, Dr Marco developed a research
                    project adopting endoscopy methods to plastic surgery in 1991, and developed new techniques in
                    aesthetic and reconstructive minimally invasive/keyhole procedures. He has achieved multiple
                    international awards for his contributions, and has since been using endoscopy to perform tummy
                    tucks, breast reduction/lifting, breast augmentation, face lift, butt lift, amongst other
                    procedures.
                  </p>
                  <p class="mb-4">
                    Find out more about the customized treatments that are suitable for endoscopic/robotic surgery <a
                      href="minimally-invasive-surgery" class="text-sitePurple font-bold">here.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-8 lg:pt-20 lg:pb-12 max-w-screen-lg pt-12 pb-4 mx-auto">
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="w-full px-4 overflow-hidden">
            <p class="mb-12">Dr Marco adopts a patient-first approach in his care, and has built up partnerships with
              hospitals around Singapore for maximum patient convenience and flexibility.</p>
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-01.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-02.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-03.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-04.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-05.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-06.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-07.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-08.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-09.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-15.jpg">
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pb-16 max-w-screen-xl pt-0 pb-8 mx-auto">
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <img class="mb-4" src="images/image-clinic.jpg">
          </div>
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <div class="lg:pl-8">
              <h3 class="md:mb-5 lg:text-4xl lg:mb-6 mb-4 text-3xl font-semibold leading-tight uppercase">Dr Marco Faria
                Correa Plastic Surgery</h3>
              <p class="mb-8">
                Dr Marco Faria Correa Plastic Surgery is a plastic surgery center that is dedicated to safe
                beautification and aesthetic enhancement. Led by internationally trained plastic surgeon Dr Marco, all
                treatments provided are safe and tailored for natural-looking results. Dr Marco places a large emphasis
                on patient satisfaction, driving him towards innovation and introducing of modern and safer methods to
                the field of plastic surgery. These include methods such as endoscopic plastic surgery and the robotic
                tummy tuck.
              </p>
              <a href="about-us" class="bg-sitePurple lg:text-lg inline-block px-10 py-3 mb-4 text-white uppercase">Read
                More</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <SiteConsultation />
    <SiteForm />
    <SiteFooter />
  </div>
</template>

<style>
  .bg-banner {
    background-image: url(/images/bg-banner.png);
  }
  .bg-banner-2 {
    background-image: url(/images/bg-banner-2.png);
  }
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
  @media (min-width: 768px) {

  }
  @media (min-width: 1024px) {

  }
</style>

<script>
  import SiteHeader from '@/components/SiteHeader.vue'
  import SiteForm from '@/components/SiteForm.vue'
  import SiteConsultation from '@/components/SiteConsultation.vue'
  import SiteFooter from '@/components/SiteFooter.vue'
  import { Carousel, Slide } from 'vue-carousel'
  import { CollapseTransition } from 'vue2-transitions'
  export default {
    components: {
      CollapseTransition,
      Carousel,
      Slide,
      SiteHeader,
      SiteConsultation,
      SiteForm,
      SiteFooter
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>